<template>
  <CLoading :loading="loading" />
  <div class="q-mb-sm" :style="step !== 1 ? 'margin-top: -48px' : ''">
    <TestModeSelector
      v-model="isTestMode"
      @update:detailCopy="onDetailCopy"
      :isShowTestModal="step > 2"
      :isHideCopy="step === 1"
      :story="form"
      v-if="step !== 1"
    />
  </div>
  <div class="column no-wrap justify-between" style="height: calc(100vh - 160px)">
    <div class="row no-wrap" style="border-top: 1px solid #d0d2e0; border-bottom: 1px solid #d0d2e0; flex: 1">
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="border-right: 1px solid #d0d2e0; overflow-y: auto; min-width: 220px; max-height: calc(100vh - 220px)"
      >
        <Stepper
          v-model="step"
          :form="form"
          :isTestMode="isTestMode"
          :selected-push-index="selectedPushIndex"
          @update:selected-push-index="(value) => (selectedPushIndex = value)"
          @next-step="nextStep"
          @delete-push="deletePush"
          @openStartSettings="openStartSettings"
        ></Stepper>
      </div>

      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
        <div v-if="step === 0" class="flex justify-center items-center full-height">
          <ChooseTriggerType v-model="form.trigger_type" @update:stepValue="step = 1"></ChooseTriggerType>
        </div>
        <div v-if="step === 1" class="flex q-ma-md">
          <div class="q-pr-sm" style="flex-basis: 60%">
            <div class="text-h5">{{ $t('label.story.story_settings') }}</div>
            <q-form ref="formRef" class="q-pt-md" greedy>
              <div class="row">
                <div class="flex justify-end items-center q-pr-sm" style="width: 150px">
                  <label class="text-grey-9 text-bold"> {{ $t('label.story.trigger') }}</label>
                </div>
                <q-input v-model="triggerTypeName" outlined dense class="col" disable />
                <!-- <q-select
                  v-model="form.trigger_type"
                  :options="triggerOptions"
                  outlined
                  dense
                  emit-value
                  map-options
                  class="col"
                /> -->
              </div>
              <!-- <div v-if="form.campaign?.display_id" class="row q-pt-md">
                <div class="flex justify-end items-center q-pr-sm" style="width: 150px">
                  <label class="text-grey-9 text-bold">{{ $t('label.display_id') }}</label>
                </div>
                <TagInputContent :tagValue="form.campaign.display_id" class="col"></TagInputContent>
              </div> -->
              <div class="row q-pt-md">
                <div class="flex justify-end items-center q-pr-sm" style="width: 150px">
                  <label class="text-grey-9 text-bold">{{ $t('label.story.story_name') }}</label>
                </div>
                <q-input
                  outlined
                  dense
                  lazy-rules
                  v-model="form.story_name"
                  :rules="[(val) => (val && val.length > 0) || requiredRule.storyName]"
                  :placeholder="$t('label.story.story_name')"
                  class="col"
                />
              </div>
            </q-form>
          </div>
        </div>
        <PopupDeliver
          ref="popupDeliver"
          v-if="
            step > 1 &&
            (form.trigger_type === 'default' ||
              form.trigger_type === 'qr_code' ||
              form.trigger_type === 'popup_deliver')
          "
          v-model="form"
          :is-setting="isSetting"
          :step="step"
          :selectedPushIndex="selectedPushIndex"
          :isTestMode="isTestMode"
          :isOpenStartSettings="isOpenStartSettings"
          :isCopy="isCopy"
          @update:isSettingValue="(value) => (isSetting = value)"
          @update:stepValue="(value) => (step = value)"
          @update:onSave="doSave"
          @update:onNextStep="onNextStep"
          @update:openStartSettings="(value) => (isOpenStartSettings = value)"
          :key="componentKey"
        />
        <Audience
          ref="audience"
          v-if="step > 1 && form.trigger_type === 'audience'"
          v-model="form"
          :step="step"
          :selectedPushIndex="selectedPushIndex"
          :isTestMode="isTestMode"
          :isOpenStartSettings="isOpenStartSettings"
          :isCopy="isCopy"
          @update:stepValue="(value) => (step = value)"
          @update:onSave="doSave"
          @update:onNextStep="onNextStep"
          :key="componentKey"
        ></Audience>
      </div>
    </div>
    <div class="row no-wrap items-center q-my-md" v-if="step > 0">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"></div>
      <div class="col">
        <div class="row">
          <div class="col"></div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12" style="min-width: 450px">
            <q-space />
            <div class="q-gutter-sm flex justify-end">
              <q-btn no-caps @click="onCancel" class="btn-cancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps :disable="!stateEdit" @click="onRevert" v-if="step >= 3" class="btn-revert">{{
                $t('revert')
              }}</q-btn>
              <q-btn no-caps @click="onSubmit" class="btn-save">{{ saveBtnText }}</q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator'
import { mixins, Options } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { maska } from 'maska'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { ICampaign, IInitialCampaign, IPopup, IPushCampaign, IStory } from '@/utils/types'
import PopupDeliver from '@/components/story/trigger/PopupDeliver.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN, ACTION_INITIAL, ACTION_POPUP, ACTION_PUSH, ACTION_STORY } from '@/store/actions'
import { constant, DELIVERY_TARGET_TYPE, STARTING_POINT_TYPE, TRIGGER_TYPE, UNIT_TYPE } from '@/utils/constants'
import ChooseTriggerType from '@/components/story/common/ChooseTriggerType.vue'
import Stepper from '@/components/story/common/Stepper.vue'
import { ObjectUtils } from '@/utils/objects'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import Audience from '@/components/story/trigger/Audience.vue'

@Options({
  components: { Audience, Stepper, ChooseTriggerType, TagInputContent, PopupDeliver, CLoading, TestModeSelector },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    return await this.checkDraftValue()
  },
})
export default class StorySettingForm extends mixins(BaseFormMixin) {
  loading = false
  step = 0
  storyStep = 1
  isSetting = false
  isTestMode = false
  isCopy = false
  isOpenStartSettings = false

  componentKey = 0
  selectedPushIndex = 0
  triggerOptions = [
    {
      label: 'デフォルト',
      value: TRIGGER_TYPE.DEFAULT,
    },
    // {
    //   label: 'QRコード/URL',
    //   value: TRIGGER_TYPE.QR_CODE,
    // },
    {
      label: 'ポップアップ',
      value: TRIGGER_TYPE.POPUP_DELIVER,
    },
    // {
    //   label: 'セグメント',
    //   value: TRIGGER_TYPE.AUDIENCE,
    // },
    // {
    //   label: '一斉配信',
    //   value: TRIGGER_TYPE.ALL,
    // },
  ]

  form: IStory = {
    _id: '',
    app_id: '',
    display_id: '',
    story_name: '',
    is_active: false,
    is_test_mode: false,
    is_add_popup: true,
    trigger_type: '',
    campaign: {},
    initial: {},
    push: {},
    pushes: [],
    popups: [],
  }

  blankPush: IPushCampaign = {
    _id: '',
    title: '',
    app_id: this.appId,
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      unit_type: UNIT_TYPE.MESSAGE_QA_UNIT,
      delivery_target_type: DELIVERY_TARGET_TYPE.RESPONDING_USERS,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: 'multi_time',
        schedule_by_type: 'by_delay_date',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      unit_type: UNIT_TYPE.MESSAGE_QA_UNIT,
      delivery_target_type: DELIVERY_TARGET_TYPE.RESPONDING_USERS,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },
      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: 'multi_time',
        schedule_by_type: 'by_delay_date',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  get requiredRule() {
    return {
      storyName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.story.story_name'),
      }),
    }
  }

  get triggerTypeName() {
    for (const item of this.triggerOptions) {
      if (this.form.trigger_type === item.value) {
        return item.label
      }
    }
    return ''
  }

  get selectedStoryId() {
    return this.$route.params.story_id
  }

  get actionType() {
    return this.$route.params.action_type || ''
  }

  get copyType() {
    return this.$route.params.copy_type || ''
  }

  get saveBtnText() {
    if (this.step >= 3) {
      return this.$t('reflect')
    }
    return this.$t('save')
  }

  get stateEdit() {
    if (!this.initForm.initial?.prod_detail || !this.form.initial?.prod_detail) {
      return false
    }
    return (
      Object.keys(this.initForm.initial?.prod_detail?.scenarios).length &&
      ObjectUtils.isDifference(this.form.initial?.prod_detail?.scenarios, this.initForm.initial?.prod_detail?.scenarios)
    )
  }

  onNextStep() {
    this.step++
  }

  @Watch('step', { immediate: true })
  handleStoryStep() {
    if (this.form.popups?.length) {
      this.storyStep = 2
    }
    if (this.form.initial?.title) {
      this.storyStep = 3
    }
    if (this.form.pushes?.length) {
      this.storyStep = 4
    }
    // console.log(this.storyStep, 'storyStep')
  }

  nextStep() {
    if (this.step <= this.storyStep) {
      if (this.storyStep < 4) {
        if (this.form.trigger_type === TRIGGER_TYPE.DEFAULT && this.storyStep === 1) {
          this.storyStep = this.storyStep + 1
          this.step = this.storyStep
        } else {
          this.step = this.storyStep + 1
        }
      } else {
        this.step = 4
      }
      if (this.step > 3) {
        this.form.pushes = [...(this.form.pushes ?? []), cloneDeep(this.blankPush)]
        this.selectedPushIndex = this.form.pushes.length - 1
      }
    } else {
      if (this.step === 1) {
        this.$refs.formRef
          .validate()
          .then(async (success: boolean) => {
            if (!success) {
              return
            }

            this.step++
          })
          .catch((error: unknown) => {
            console.log('error', error)
          })
      }
      if (this.step === 2 || this.step === 3) {
        if (
          this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
          this.form.trigger_type === TRIGGER_TYPE.QR_CODE ||
          this.form.trigger_type === TRIGGER_TYPE.DEFAULT
        ) {
          this.$refs.popupDeliver.validate(false)
        }
      }
      if ((this.form.trigger_type === 'audience' && this.step >= 2) || this.step >= 3) {
        this.form.pushes = [...(this.form.pushes ?? []), cloneDeep(this.blankPush)]
        this.selectedPushIndex = this.form.pushes.length - 1
      }
    }
  }

  onSubmit() {
    if (this.step <= 1) {
      this.$refs.formRef
        .validate()
        .then(async (success: boolean) => {
          if (!success) {
            return
          }

          await this.doSave()
        })
        .catch((error: unknown) => {
          console.log('error', error)
        })
    } else {
      console.log(this.step, 'onSubmit step step')
      if (
        this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
        this.form.trigger_type === TRIGGER_TYPE.QR_CODE ||
        this.form.trigger_type === TRIGGER_TYPE.DEFAULT
      ) {
        this.$refs.popupDeliver.validate(true)
      } else if (this.form.trigger_type === TRIGGER_TYPE.AUDIENCE) {
        this.$refs.audience.validate(true)
      }
    }
  }

  async doSave() {
    console.log('doSave')
    // let message = ''
    // if (this.form.trigger_type === TRIGGER_TYPE.DEFAULT) {
    //   if (this.form.is_active) {
    //     const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
    //       app_id: this.form.app_id,
    //       story_id: this.form._id,
    //       is_test_mode: false,
    //     })
    //     // eslint-disable-next-line
    //     if (resp && resp['result']) {
    //       message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
    //     }
    //   }

    //   if (this.form.is_test_mode && message === '') {
    //     const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
    //       app_id: this.form.app_id,
    //       story_id: this.form._id,
    //       is_test_mode: true,
    //     })
    //     // eslint-disable-next-line
    //     if (resp && resp['result']) {
    //       message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
    //     }
    //   }

    //   if (message !== '') {
    //     this.$q.dialog({
    //       title: 'ご確認下さい',
    //       message: message,
    //       persistent: true,
    //       html: true,
    //       class: 'q-dialog-plugin-custom',
    //     })
    //     return
    //   }
    // }

    this.loading = true
    const { dispatch } = this.$store
    let item: IStory = {}

    this.form.app_id = this.appId

    // Handle form for copy
    if (this.actionType === constant.ACTION_TYPE.COPY) {
      this.form._id = ''
      if (this.form.campaign) {
        this.form.campaign._id = ''
        this.form.campaign.story_id = ''
      }
      if (this.form.initial) {
        this.form.initial._id = ''
        this.form.initial.story_id = ''
      }
      if (this.form.push) {
        this.form.push._id = ''
        this.form.push.story_id = ''
      }

      if (this.form.popups) {
        for (const p of this.form.popups) {
          p._id = ''
          p.campaign_id = ''
        }
      }
      if (this.form.pushes) {
        for (const p of this.form.pushes) {
          p._id = ''
          p.story_id = ''
        }
      }
    }

    if (this.form.campaign) {
      this.form.campaign.is_active = this.form.is_active
      this.form.campaign.is_test_mode = this.form.is_test_mode
    }
    if (this.form.pushes) {
      for (const push of this.form.pushes) {
        push.is_active = this.form.is_active
        push.is_test_mode = this.form.is_test_mode
      }
    }
    if (this.form._id) {
      await dispatch(ACTION_STORY.UPDATE, {
        _id: this.form._id,
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_STORY.ADD, {
        ...this.form,
      })
      if (item) {
        this.form._id = item._id
      }
    }

    if (
      this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
      this.form.trigger_type === TRIGGER_TYPE.QR_CODE ||
      this.form.trigger_type === TRIGGER_TYPE.DEFAULT
    ) {
      if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER || this.form.trigger_type === TRIGGER_TYPE.QR_CODE) {
        if (this.form.campaign?.prod_detail) {
          this.form.campaign.story_id = this.form._id
          await this.saveCampaign(this.form.campaign)
        }
      }
      if (this.form.initial?.title) {
        this.form.initial.story_id = this.form._id
        await this.saveInitial(this.form.initial)
      }
    }

    if (this.form.pushes) {
      await this.savePushes(this.form.pushes)
    }

    // await this.initialSory(this.form._id)

    this.handleStoryStep()
    this.componentKey++
    this.loading = false
    this.$q.notify({
      message: this.$t('messages.saved'),
      color: 'positive',
    })

    this.initForm = cloneDeep(this.parentForm)
    this.goto('story_setting', {
      app_id: this.appId,
      story_id: this.form._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async saveCampaign(campaign: ICampaign) {
    if (!campaign._id) {
      campaign.app_id = this.appId
      campaign.campaign_name = this.form.story_name
    }
  }

  async saveInitial(initial: IInitialCampaign) {
    if (!initial._id) {
      initial.is_active = false
      initial.is_test_mode = false
      initial.app_id = this.appId
      initial.test_detail = initial.prod_detail
    }
    initial.title = '初回メッセージ'
  }

  async savePushes(pushes: IPushCampaign[]) {
    for (const push of pushes) {
      if (!push._id) {
        push.app_id = this.appId
        push.story_id = this.form._id
        if (push.test_detail) {
          push.test_detail = {
            direction_type: push.prod_detail?.direction_type,
            scenarios: push.prod_detail?.scenarios,
            frequency: push.test_detail?.frequency,
            schedule: push.test_detail?.schedule,
            audience: push.test_detail.audience,
          }
        }
      }
    }
  }

  async onRevert() {
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, this.form._id)
      if (initial) {
        this.form.initial = initial
        this.step = 3
        this.componentKey++
      }
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async initialSory(storyId) {
    console.log(storyId, 'storyId')

    if (storyId) {
      const item: IStory = await this.$store.dispatch(ACTION_STORY.LOAD_ITEM, storyId)
      if (item) {
        if (this.actionType === constant.ACTION_TYPE.COPY && this.copyType !== 'normal') {
          item.trigger_type = this.copyType
        }

        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)
        if (
          item.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
          item.trigger_type === TRIGGER_TYPE.QR_CODE ||
          item.trigger_type === TRIGGER_TYPE.DEFAULT
        ) {
          if (
            item.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
            item.trigger_type === TRIGGER_TYPE.QR_CODE ||
            item.trigger_type === TRIGGER_TYPE.DEFAULT
          ) {
            const campaign: ICampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_STORY_ITEM, storyId)
            if (campaign._id !== '') {
              item.campaign = campaign
              const popups: IPopup[] = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
                app_id: this.appId,
                campaign_id: campaign._id,
                is_test_mode: this.isTestMode,
              })
              item.popups = popups
            }
          }

          const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
          if (initial._id !== '') {
            item.initial = initial
          }

          item.pushes = pushes
        } else if (item.trigger_type === TRIGGER_TYPE.AUDIENCE) {
          if (pushes.length > 0) {
            item.push = pushes[0]
            item.pushes = pushes
          }
        }

        if (this.actionType === constant.ACTION_TYPE.COPY) {
          item.story_name = item.story_name + ' Copy'
          item.is_test_mode = false
          item.is_active = false
          item.is_add_popup = true
          item.created_at = undefined
          item.updated_at = undefined

          if (item.campaign) {
            item.campaign.campaign_name = item.campaign.campaign_name + ' Copy'
            item.campaign.is_active = false
            item.campaign.is_test_mode = false
          }
          if (item.initial) {
            item.initial.title = item.initial.title + ' Copy'
            item.initial.is_active = false
            item.initial.is_test_mode = false
            if (item.initial.prod_detail) {
              item.initial.prod_detail = await ObjectUtils.copyScenario(this.$store, item.initial.prod_detail)
            }
            if (item.initial.test_detail) {
              item.initial.test_detail = await ObjectUtils.copyScenario(this.$store, item.initial.test_detail)
            }
          }
          if (item.push) {
            item.push.is_active = false
            item.push.is_test_mode = false
            item.push.title = item.push.title + ' Copy'
            if (item.push.prod_detail) {
              item.push.prod_detail = await ObjectUtils.copyScenario(this.$store, item.push.prod_detail)
            }
            if (item.push.test_detail) {
              item.push.test_detail = await ObjectUtils.copyScenario(this.$store, item.push.test_detail)
            }
          }
          if (item.pushes) {
            for (const p of item.pushes) {
              p.is_active = false
              p.is_test_mode = false
              p.title = p.title + ' Copy'
              if (p.prod_detail) {
                p.prod_detail = await ObjectUtils.copyScenario(this.$store, p.prod_detail)
              }
              if (p.test_detail) {
                p.test_detail = await ObjectUtils.copyScenario(this.$store, p.test_detail)
              }
            }
          }
        }
        this.form = { ...this.form, ...cloneDeep(item) }
        console.log(this.form)
      }
    }
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    this.componentKey++
  }

  onDetailCopy() {
    this.isCopy = !this.isCopy
  }

  async deletePush(index) {
    if (this.form.pushes) {
      const push: IPushCampaign = this.form.pushes[index]
      if (push) {
        this.handleResetAudiences(index)
        this.form.pushes.splice(index, 1)
        this.handleCurrentStep()
        this.componentKey++

        // if (push._id) {
        //   this.$q
        //     .dialog({
        //       title: this.$t('confirm'),
        //       message: this.$t('are_you_sure_you_want_to_delete'),
        //       cancel: true,
        //       persistent: true,
        //     })
        //     .onOk(async () => {
        //       const success = await this.$store.dispatch(ACTION_PUSH.DELETE, push._id)
        //       if (success) {
        //         this.$q.notify({
        //           message: this.$t('messages.deleted'),
        //           color: 'positive',
        //         })
        //         if (this.form.pushes) {
        //           this.form.pushes.splice(index, 1)
        //           this.handleCurrentStep()
        //         }
        //       }
        //     })
        // } else {
        //   this.form.pushes.splice(index, 1)
        //   this.handleCurrentStep()
        // }
      }
    }
  }

  handleResetAudiences(index) {
    if (this.form.pushes && this.form.pushes.length > index) {
      const push = this.form.pushes[index + 1]
      if (push && push.prod_detail && push.prod_detail.audience) {
        push.prod_detail.audience.card_not = []
        push.prod_detail.audience.card_or = []
        push.prod_detail.audience.unreached_users = []
      }
      if (push && push.test_detail && push.test_detail.audience) {
        push.test_detail.audience.card_not = []
        push.test_detail.audience.card_or = []
        push.test_detail.audience.unreached_users = []
      }
    }
  }

  handleCurrentStep() {
    if (this.form.pushes && this.form.pushes.length) {
      this.step = 4
      this.selectedPushIndex = 0
    } else {
      this.step = 3
    }
  }

  openStartSettings() {
    this.isOpenStartSettings = true
  }

  @Watch('selectedStoryId')
  async onSelectedStoryIdChange() {
    this.loading = true
    if (this.selectedStoryId) {
      this.step = 1
    } else {
      this.step = 0
    }

    await this.initialSory(this.selectedStoryId)
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.loading = false
    if (this.form._id) {
      this.isSetting = true
    }
    this.handleStoryStep()
  }

  async created() {
    await this.onSelectedStoryIdChange()
  }
}
</script>
