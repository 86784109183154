<template>
  <q-dialog v-model="visible">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm"> シナリオテンプレート </q-toolbar-title>
        <!-- <div class="row no-wrap items-center full-width justify-end">
          <q-btn size="sm" flat round color="black" icon="settings_backup_restore" @click="onRefresh"> </q-btn>
        </div> -->
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <div class="row horizontal-line" style="height: 70vh">
          <div class="col-4 vertical-line">
            <q-tabs
              v-model="tab"
              dense
              align="left"
              class="text-grey-6"
              active-color="blue-8"
              indicator-color="blue-8"
              narrow-indicator
              no-caps
            >
              <q-tab class="left-tab" name="load" label="シナリオ一覧" />
            </q-tabs>

            <q-separator />

            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="load">
                <div class="row q-mb-sm">
                  <q-input outlined dense v-model="search" debounce="1000" class="col q-mr-sm">
                    <template v-slot:append>
                      <q-icon name="search" color="text-prey-3" />
                    </template>
                  </q-input>
                  <q-select
                    class="col-5"
                    outlined
                    dense
                    emit-value
                    map-options
                    v-model="sortBy"
                    :options="sortOptions"
                    label="Sort by"
                  />
                </div>
                <div style="max-height: calc(70vh - 120px); overflow-y: auto">
                  <div class="row" v-for="(item, index) in filteredScenarioList" :key="index">
                    <div
                      class="col initial cursor-pointer"
                      :class="selectedScenarioId === item.id ? 'selected' : ''"
                      @click="selectScenario(item)"
                      style="position: relative"
                    >
                      <div class="row q-ma-sm">
                        <q-icon
                          name="history_edu"
                          class="flag-icon"
                          :class="campaignType === 'initial' ? 'flag-icon__initial' : 'flag-icon__push'"
                          size="1.5em"
                        />
                        <div class="text-grey-6 q-ml-sm flex items-center">{{ item.title }}<br /></div>
                      </div>
                      <div class="text-caption">
                        {{ item.id.length > 4 ? item.id.slice(0, 4) + '***' : item.id }}
                      </div>
                    </div>
                  </div>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </div>
          <div class="col">
            <div class="row title-scenario">プレビュー</div>
            <div class="row q-pr-md q-pl-md q-pb-md" style="width: 100%; height: 87%">
              <iframe :id="'myFrame-copy-' + campaignId" class="scenario-iframe" :src="iframeURL()" />
            </div>

            <div class="row no-wrap items-center q-pr-md">
              <q-space />
              <div class="q-gutter-sm">
                <q-btn no-caps class="btn-save" @click="onSubmit">追加</q-btn>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IInitialCampaign, IInitialCampaignDetail, IPushCampaign, IScenarioTemplate, IStory } from '@/utils/types'
import { SCENARIO_MAKER } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'
import { ACTION_INITIAL, ACTION_PUSH, ACTION_SCENARIO_TEMPLATE, ACTION_STORY } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { randomString } from '@/utils/helpers'
import { ObjectUtils } from '@/utils/objects'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue', 'update:changeScenario', 'update:resetScenario'],
})
export default class CopyScenariosModal extends Vue {
  @Prop({ default: [] })
  modelValue!: ICard[]

  @Prop()
  modalVisible!: boolean

  @Prop()
  appId!: string

  @Prop({ default: false })
  isTestMode!: boolean

  @Prop()
  campaignId!: string

  @Prop()
  campaignType!: string

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop()
  selectedPushIndex!: number

  @Prop({ default: false })
  isReport!: boolean

  @Prop()
  story!: IStory

  tab = 'load'
  isApply = false
  scenarioTemplates: IScenarioTemplate[] = []
  currentScenario: ICard[] = []
  initials: IInitialCampaign[] = []
  pushs: IPushCampaign[] = []
  selectedScenarioId = ''
  search = ''
  sortBy = ''

  get value(): ICard[] {
    return this.modelValue
  }

  set value(val: ICard[]) {
    this.$emit('update:modelValue', val)
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  get scenarioList() {
    const items: {
      title?: string
      id?: string
      scenarios?: ICard[]
      updated_at?: string
      created_at?: string
      type?: string
    }[] = []
    if (this.campaignType === 'push') {
      this.pushs.forEach((push) => {
        items.push({
          title: push.title,
          id: push._id,
          scenarios: this.isTestMode ? push.test_detail?.scenarios : push.prod_detail?.scenarios,
          created_at: push.created_at,
          updated_at: push.updated_at,
          type: 'push',
        })
      })
    } else {
      this.initials.forEach((initial) => {
        items.push({
          title: initial.title,
          id: initial._id,
          scenarios: this.isTestMode ? initial.test_detail?.scenarios : initial.prod_detail?.scenarios,
          created_at: initial.created_at,
          updated_at: initial.updated_at,
          type: 'initial',
        })
      })
    }
    this.scenarioTemplates.forEach((template) => {
      items.push({
        title: template.title,
        id: template._id,
        scenarios: template.scenario,
        created_at: template.created_at,
        updated_at: template.updated_at,
        type: 'template',
      })
    })
    if (this.sortBy === 'created_at_desc') {
      return items.sort(
        (a, b) =>
          (b.created_at ? new Date(b.created_at).getTime() : 0) - (a.created_at ? new Date(a.created_at).getTime() : 0)
      )
    } else if (this.sortBy === 'created_at_asc') {
      return items.sort(
        (a, b) =>
          (a.created_at ? new Date(a.created_at).getTime() : 0) - (b.created_at ? new Date(b.created_at).getTime() : 0)
      )
    } else if (this.sortBy === 'updated_at_desc') {
      return items.sort(
        (a, b) =>
          (b.updated_at ? new Date(b.updated_at).getTime() : 0) - (a.updated_at ? new Date(a.updated_at).getTime() : 0)
      )
    } else if (this.sortBy === 'updated_at_asc') {
      return items.sort(
        (a, b) =>
          (a.updated_at ? new Date(a.updated_at).getTime() : 0) - (b.updated_at ? new Date(b.updated_at).getTime() : 0)
      )
    } else {
      return items
    }
  }

  get filteredScenarioList() {
    if (!this.search) {
      return this.scenarioList
    }
    const query = this.search.toLowerCase()
    return this.scenarioList.filter(
      (item) =>
        (item.title && item.title.toLowerCase().includes(query)) || (item.id && item.id.toLowerCase().includes(query))
    )
  }

  get sortOptions() {
    return [
      {
        value: 'updated_at_desc',
        label: '更新日（降順）',
      },
      {
        value: 'updated_at_asc',
        label: '更新日（昇順）',
      },
      {
        value: 'created_at_desc',
        label: '作成日（降順）',
      },
      {
        value: 'created_at_asc',
        label: '作成日（昇順）',
      },
    ]
  }

  iframeURL() {
    let isBlankPush = true
    if (this.campaignType === 'push') {
      const push: IPushCampaign = this.campaign
      if (this.isTestMode) {
        if (push.test_detail?.schedule?.time && push.test_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      } else {
        if (push.prod_detail?.schedule?.time && push.prod_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      }
    }
    return this.$router.resolve({
      name: 'story_scenario_maker',
      params: {
        appId: this.appId,
        isReport: this.isReport,
        isTestMode: this.isTestMode,
        campaignType: this.campaignType,
        index: this.selectedPushIndex,
        campaignId: this.campaignId,
        isReadOnly: true,
      },
      query: {
        isBlankPush: isBlankPush,
        isHideHeader: true,
      },
    })?.fullPath
  }

  // eslint-disable-next-line
  postToIFrame(action: string, data: any) {
    // eslint-disable-next-line
    const iframeEl = document.getElementById('myFrame-copy-' + this.campaignId) as any
    if (!iframeEl) {
      return
    }

    iframeEl.contentWindow.postMessage(
      {
        action,
        data: {
          data: JSON.stringify(data),
          isTestMode: this.isTestMode,
        },
      },
      '*'
    )
  }

  handleMessage(event) {
    const action = event?.data?.action
    if (action === SCENARIO_MAKER.FROM_IFRAME.READY) {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.LOAD_SCENARIO, this.value)
    }
  }

  onChangeScenario(value: ICard[]) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.LOAD_SCENARIO, value)
  }

  selectScenario(item) {
    this.selectedScenarioId = item.id
    this.onChangeScenario(item.scenarios ?? [])
  }

  onRefresh() {
    this.selectedScenarioId = ''
    this.onChangeScenario(this.currentScenario ?? [])
    this.$emit('update:resetScenario', this.currentScenario)
  }

  async onSubmit() {
    this.isApply = true
    let detail: IInitialCampaignDetail = {
      scenarios: this.value,
    }
    detail = await ObjectUtils.copyScenario(this.$store, detail)
    this.value = detail.scenarios ?? []
    this.$emit('update:changeScenario', this.value)
    this.onCancel()
  }

  onCancel() {
    this.visible = false
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const items = cloneDeep(this.$store.getters.scenarioTemplates)
    if (items) {
      return (this.scenarioTemplates = items)
    }
  }

  @Watch('$store.getters.initials')
  initialChanged() {
    const items: IInitialCampaign[] = cloneDeep(this.$store.getters.initials)
    if (items) {
      // items.forEach(element => {
      //   if (element.story_id !="")

      // });
      return (this.initials = items)
    }
  }

  @Watch('$store.getters.pushs')
  pushChanged() {
    const items: IPushCampaign[] = cloneDeep(this.$store.getters.pushs)
    if (items) {
      return (this.pushs = items)
    }
  }

  updateScenario(scenarios: ICard[]) {
    if (scenarios) {
      for (const s of scenarios) {
        if (s.cardType !== 'start' && s.uniqueId !== 'welcome') {
          // eslint-disable-next-line dot-notation
          s['old_uniqueId'] = cloneDeep(s.uniqueId)
          s.uniqueId = randomString()
        }
      }

      scenarios = this.updateUniqueIdNextCard(scenarios)
    }
    return scenarios
  }

  updateUniqueIdNextCard(scenarios: ICard[]) {
    for (const scenario of scenarios) {
      for (const answer of scenario.answers) {
        for (const nextCard of answer.nextCards) {
          const uniqueId = this.getUniqueIdNextCard(scenarios, nextCard.uniqueId)
          if (uniqueId) {
            // eslint-disable-next-line dot-notation
            nextCard['old_uniqueId'] = cloneDeep(nextCard.uniqueId)
            nextCard.uniqueId = uniqueId
          }
        }
      }
    }
    return scenarios
  }

  getUniqueIdNextCard(scenarios: ICard[], uniqueId) {
    for (const scenario of scenarios) {
      // eslint-disable-next-line dot-notation
      if (scenario['old_uniqueId'] === uniqueId) {
        return scenario.uniqueId
      }
    }
    return null
  }

  async mounted() {
    this.currentScenario = cloneDeep(this.value)
    window.addEventListener('message', (event) => this.handleMessage(event))

    await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITH_DETAIL, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITH_DETAIL, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.appId,
    })
  }

  beforeUnmount() {
    if (!this.isApply) {
      this.$emit('update:resetScenario', this.currentScenario)
    }
    window.removeEventListener('message', (event) => this.handleMessage(event))
  }
}
</script>

<style scoped lang="scss">
.full-card {
  width: 1400px !important;
  max-width: 90vw !important;
}
.scenario-iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}
.title-scenario {
  font-size: 12px;
  font-weight: 700;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 10px;
  color: #3982c2;
}

.left-tab {
  justify-content: start !important;
}
.initial.selected {
  background-color: #3982c233;
}
.text-caption {
  position: absolute;
  bottom: 0;
  left: 42px;
  font-size: 8px;
}
:deep(.q-tab--active) {
  background: none;
}
:deep(.q-tab-panel) {
  border: none;
}
</style>
