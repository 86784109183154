<template>
  <div class="q-mt-md">
    <q-expansion-item label="配信条件" dense class="cus-expansion">
      <q-toolbar class="text-white" style="background-color: #3982c2">
        <q-toolbar-title class="q-ml-md">配信対象シナリオ</q-toolbar-title>
      </q-toolbar>
      <draggable class="list-group" :list="reaches" group="people" itemKey="id" style="min-height: 50px">
        <template #item="{ element, index }">
          <div class="list-group-item">
            <q-item class="cursor-move q-pr-none" :key="index">
              <q-item-section style="width: 20px; display: contents">
                <q-icon name="drag_indicator" color="grey" size="sm" />
              </q-item-section>
              <q-item-section style="width: 20px; display: contents">
                <q-icon :name="iconName(element)" :style="iconBg(element)" color="white" class="sgbmk-icon" size="xs" />
              </q-item-section>
              <q-item-section class="q-ml-md">
                <q-item-label>
                  {{ element.title }}
                  <q-tooltip>{{ element.title }}</q-tooltip>
                </q-item-label>
                <q-item-label class="sub-title">サイズ: {{ element.totalUsers }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </template>
      </draggable>
      <div class="row" style="color: #c20b2a; align-items: center">
        <div class="col-auto">▼配信対象外</div>
        <div class="col q-ml-sm" style="border-top: 1px dotted #c20b2a"></div>
      </div>
      <draggable class="list-group" :list="unReached" group="people" itemKey="id" style="min-height: 50px">
        <template #item="{ element, index }">
          <div class="list-group-item">
            <q-item clickable :key="index" class="cursor-move">
              <q-item-section style="width: 20px; display: contents">
                <q-icon name="drag_indicator" color="grey" size="sm" />
              </q-item-section>
              <q-item-section style="width: 20px; display: contents">
                <q-icon :name="iconName(element)" :style="iconBg(element)" color="white" class="sgbmk-icon" size="xs" />
              </q-item-section>
              <q-item-section class="q-ml-md">
                <q-item-label>
                  {{ element.title }}
                  <q-tooltip>{{ element.title }}</q-tooltip>
                </q-item-label>
                <q-item-label class="sub-title">サイズ: {{ element.totalUsers }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </template>
      </draggable></q-expansion-item
    >
  </div>
  <div class="horizontal-line"></div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'
import { EScenarioResourceType, IPushAudience, IUserAnswer } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { USER_ANSWER } from '@/store/actions'

@Options({
  components: { draggable },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class UnReachedUsersSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    unreached_users: [],
  }

  @Prop({})
  isTestMode!: boolean

  @Prop({})
  campaignId!: string

  @Prop()
  scenarios!: ICard[]

  reaches: ICard[] = []
  unReached: ICard[] = []

  @Watch('value', { immediate: true })
  async onRefresh() {
    this.unReached = this.value.unreached_users ?? []
    this.updateApi()
  }

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  iconName(row: ICard) {
    if (row.cardType === 'question') {
      return 'help_outline'
    } else if (row.cardType === 'goal') {
      return 'verified'
    }
    return 'message'
  }

  iconBg(row: ICard) {
    if (row.cardType === 'question') {
      return 'background: #ffc10d'
    } else if (row.cardType === 'goal') {
      return 'background: #6e9c9f'
    }
    return 'background: #8f969e'
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    const usersAnswers: IUserAnswer[] = await this.$store.dispatch(USER_ANSWER.GET_USER_ANSWER_BY_CAMPAIGN, {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
    })

    this.reaches = []
    if (this.scenarios) {
      const cards = this.scenarios.filter(
        (item) =>
          item.cardType === EScenarioResourceType.message ||
          item.cardType === EScenarioResourceType.question ||
          item.cardType === EScenarioResourceType.goal
      )
      for (let index = 0; index < cards.length; index++) {
        const item = cards[index]
        if (!this.checkExistItem(item.id)) {
          const a = usersAnswers.filter((p) => p.next_unique_id === item.uniqueId)
          const uniqueUserIds = new Set(a.map((p) => p.user_id)) // Create a set of unique user IDs
          const uniqueUserIdCount = uniqueUserIds.size // Count of unique user IDs
          item.totalUsers = uniqueUserIdCount
          this.reaches.push(item)
        }
      }

      this.value.reached_users = this.reaches
    }
  }

  checkExistItem(id: string) {
    const checkExist = this.value?.unreached_users?.find((item) => item.id === id)
    if (checkExist) {
      return true
    }
    return false
  }

  onAdd(row: ICard, index: number) {
    if (!this.value.unreached_users) {
      this.value.unreached_users = []
    }
    if (this.value.reached_users) {
      this.value.reached_users.splice(index, 1)
      this.value.unreached_users.push(row)
      this.onRefresh()
    }
  }

  onUnSelected(row: ICard, index: number) {
    if (!this.value.reached_users) {
      this.value.reached_users = []
    }
    if (this.value.unreached_users) {
      this.value.unreached_users.splice(index, 1)
      this.value.reached_users.push(row)
      this.onRefresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.sgbmk-icon {
  padding: 4px;
  border: 1px solid;
  border-radius: 6px;
}

.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
}
.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  text-align: left;
}
.sub-title {
  font-size: 8px !important;
  font-weight: 400 !important;
  line-height: 8.69px !important;
  text-align: left;
  color: #8f969e;
}
.q-toolbar {
  min-height: 36px;
}
.q-toolbar__title {
  font-size: 18px;
}
:deep(.q-btn.dotted-border .q-icon) {
  font-size: 1.25em;
}
</style>
