import { render } from "./CopyScenariosModal.vue?vue&type=template&id=9e5234aa&scoped=true"
import script from "./CopyScenariosModal.vue?vue&type=script&lang=ts"
export * from "./CopyScenariosModal.vue?vue&type=script&lang=ts"

import "./CopyScenariosModal.vue?vue&type=style&index=0&id=9e5234aa&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-9e5234aa"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QInput,QIcon,QSelect,QSpace});
