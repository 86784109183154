
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { maska } from 'maska'
import { IStory } from '@/utils/types'
import CampaignPopup from '@/components/story/trigger/popup-deliver/CampaignPopup.vue'
import Initial from '@/components/story/trigger/popup-deliver/Initial.vue'
import Push from '@/components/story/trigger/popup-deliver/Push.vue'
import { TRIGGER_TYPE } from '@/utils/constants'

@Options({
  components: { Push, Initial, CampaignPopup },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:isSettingValue',
    'update:stepValue',
    'update:onSave',
    'update:onNextStep',
    'update:openStartSettings',
  ],
})
export default class PopupDeliver extends mixins(BaseFormMixin) {
  @Prop({})
  modelValue!: IStory

  @Prop()
  isSetting!: boolean

  @Prop()
  step!: number

  @Prop()
  selectedPushIndex!: number

  @Prop({})
  isTestMode!: boolean

  @Prop()
  isOpenStartSettings!: boolean

  @Prop({})
  isCopy!: boolean

  get form() {
    return this.modelValue
  }

  set form(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  get setting() {
    return this.isSetting
  }

  set setting(value: boolean) {
    this.$emit('update:isSettingValue', value)
  }

  get currentStep() {
    return this.step
  }

  set currentStep(value: number) {
    this.$emit('update:stepValue', value)
  }

  get openStartSettings() {
    return this.isOpenStartSettings
  }

  set openStartSettings(value: boolean) {
    this.$emit('update:openStartSettings', value)
  }

  validate(isSave = false) {
    if (
      this.currentStep === 2 &&
      (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER || this.form.trigger_type === TRIGGER_TYPE.QR_CODE || this.form.trigger_type === TRIGGER_TYPE.DEFAULT)
    ) {
      this.$refs.campaignPopup.validate(isSave)
    }
    if (this.currentStep === 3) {
      this.$refs.storyInitial.validate(isSave)
    }
    if (this.currentStep === 4) {
      this.$refs.storyPush.validate(isSave)
    }
  }

  onSave() {
    this.$emit('update:onSave')
  }

  onNextStep() {
    this.$emit('update:onNextStep')
  }
}
