
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IInitialCampaign, IInitialCampaignDetail, IPushCampaign, IScenarioTemplate, IStory } from '@/utils/types'
import { SCENARIO_MAKER } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'
import { ACTION_INITIAL, ACTION_PUSH, ACTION_SCENARIO_TEMPLATE, ACTION_STORY } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { randomString } from '@/utils/helpers'
import { ObjectUtils } from '@/utils/objects'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue', 'update:changeScenario', 'update:resetScenario'],
})
export default class CopyScenariosModal extends Vue {
  @Prop({ default: [] })
  modelValue!: ICard[]

  @Prop()
  modalVisible!: boolean

  @Prop()
  appId!: string

  @Prop({ default: false })
  isTestMode!: boolean

  @Prop()
  campaignId!: string

  @Prop()
  campaignType!: string

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop()
  selectedPushIndex!: number

  @Prop({ default: false })
  isReport!: boolean

  @Prop()
  story!: IStory

  tab = 'load'
  isApply = false
  scenarioTemplates: IScenarioTemplate[] = []
  currentScenario: ICard[] = []
  initials: IInitialCampaign[] = []
  pushs: IPushCampaign[] = []
  selectedScenarioId = ''
  search = ''
  sortBy = ''

  get value(): ICard[] {
    return this.modelValue
  }

  set value(val: ICard[]) {
    this.$emit('update:modelValue', val)
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  get scenarioList() {
    const items: {
      title?: string
      id?: string
      scenarios?: ICard[]
      updated_at?: string
      created_at?: string
      type?: string
    }[] = []
    if (this.campaignType === 'push') {
      this.pushs.forEach((push) => {
        items.push({
          title: push.title,
          id: push._id,
          scenarios: this.isTestMode ? push.test_detail?.scenarios : push.prod_detail?.scenarios,
          created_at: push.created_at,
          updated_at: push.updated_at,
          type: 'push',
        })
      })
    } else {
      this.initials.forEach((initial) => {
        items.push({
          title: initial.title,
          id: initial._id,
          scenarios: this.isTestMode ? initial.test_detail?.scenarios : initial.prod_detail?.scenarios,
          created_at: initial.created_at,
          updated_at: initial.updated_at,
          type: 'initial',
        })
      })
    }
    this.scenarioTemplates.forEach((template) => {
      items.push({
        title: template.title,
        id: template._id,
        scenarios: template.scenario,
        created_at: template.created_at,
        updated_at: template.updated_at,
        type: 'template',
      })
    })
    if (this.sortBy === 'created_at_desc') {
      return items.sort(
        (a, b) =>
          (b.created_at ? new Date(b.created_at).getTime() : 0) - (a.created_at ? new Date(a.created_at).getTime() : 0)
      )
    } else if (this.sortBy === 'created_at_asc') {
      return items.sort(
        (a, b) =>
          (a.created_at ? new Date(a.created_at).getTime() : 0) - (b.created_at ? new Date(b.created_at).getTime() : 0)
      )
    } else if (this.sortBy === 'updated_at_desc') {
      return items.sort(
        (a, b) =>
          (b.updated_at ? new Date(b.updated_at).getTime() : 0) - (a.updated_at ? new Date(a.updated_at).getTime() : 0)
      )
    } else if (this.sortBy === 'updated_at_asc') {
      return items.sort(
        (a, b) =>
          (a.updated_at ? new Date(a.updated_at).getTime() : 0) - (b.updated_at ? new Date(b.updated_at).getTime() : 0)
      )
    } else {
      return items
    }
  }

  get filteredScenarioList() {
    if (!this.search) {
      return this.scenarioList
    }
    const query = this.search.toLowerCase()
    return this.scenarioList.filter(
      (item) =>
        (item.title && item.title.toLowerCase().includes(query)) || (item.id && item.id.toLowerCase().includes(query))
    )
  }

  get sortOptions() {
    return [
      {
        value: 'updated_at_desc',
        label: '更新日（降順）',
      },
      {
        value: 'updated_at_asc',
        label: '更新日（昇順）',
      },
      {
        value: 'created_at_desc',
        label: '作成日（降順）',
      },
      {
        value: 'created_at_asc',
        label: '作成日（昇順）',
      },
    ]
  }

  iframeURL() {
    let isBlankPush = true
    if (this.campaignType === 'push') {
      const push: IPushCampaign = this.campaign
      if (this.isTestMode) {
        if (push.test_detail?.schedule?.time && push.test_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      } else {
        if (push.prod_detail?.schedule?.time && push.prod_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      }
    }
    return this.$router.resolve({
      name: 'story_scenario_maker',
      params: {
        appId: this.appId,
        isReport: this.isReport,
        isTestMode: this.isTestMode,
        campaignType: this.campaignType,
        index: this.selectedPushIndex,
        campaignId: this.campaignId,
        isReadOnly: true,
      },
      query: {
        isBlankPush: isBlankPush,
        isHideHeader: true,
      },
    })?.fullPath
  }

  // eslint-disable-next-line
  postToIFrame(action: string, data: any) {
    // eslint-disable-next-line
    const iframeEl = document.getElementById('myFrame-copy-' + this.campaignId) as any
    if (!iframeEl) {
      return
    }

    iframeEl.contentWindow.postMessage(
      {
        action,
        data: {
          data: JSON.stringify(data),
          isTestMode: this.isTestMode,
        },
      },
      '*'
    )
  }

  handleMessage(event) {
    const action = event?.data?.action
    if (action === SCENARIO_MAKER.FROM_IFRAME.READY) {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.LOAD_SCENARIO, this.value)
    }
  }

  onChangeScenario(value: ICard[]) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.LOAD_SCENARIO, value)
  }

  selectScenario(item) {
    this.selectedScenarioId = item.id
    this.onChangeScenario(item.scenarios ?? [])
  }

  onRefresh() {
    this.selectedScenarioId = ''
    this.onChangeScenario(this.currentScenario ?? [])
    this.$emit('update:resetScenario', this.currentScenario)
  }

  async onSubmit() {
    this.isApply = true
    let detail: IInitialCampaignDetail = {
      scenarios: this.value,
    }
    detail = await ObjectUtils.copyScenario(this.$store, detail)
    this.value = detail.scenarios ?? []
    this.$emit('update:changeScenario', this.value)
    this.onCancel()
  }

  onCancel() {
    this.visible = false
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const items = cloneDeep(this.$store.getters.scenarioTemplates)
    if (items) {
      return (this.scenarioTemplates = items)
    }
  }

  @Watch('$store.getters.initials')
  initialChanged() {
    const items: IInitialCampaign[] = cloneDeep(this.$store.getters.initials)
    if (items) {
      // items.forEach(element => {
      //   if (element.story_id !="")

      // });
      return (this.initials = items)
    }
  }

  @Watch('$store.getters.pushs')
  pushChanged() {
    const items: IPushCampaign[] = cloneDeep(this.$store.getters.pushs)
    if (items) {
      return (this.pushs = items)
    }
  }

  updateScenario(scenarios: ICard[]) {
    if (scenarios) {
      for (const s of scenarios) {
        if (s.cardType !== 'start' && s.uniqueId !== 'welcome') {
          // eslint-disable-next-line dot-notation
          s['old_uniqueId'] = cloneDeep(s.uniqueId)
          s.uniqueId = randomString()
        }
      }

      scenarios = this.updateUniqueIdNextCard(scenarios)
    }
    return scenarios
  }

  updateUniqueIdNextCard(scenarios: ICard[]) {
    for (const scenario of scenarios) {
      for (const answer of scenario.answers) {
        for (const nextCard of answer.nextCards) {
          const uniqueId = this.getUniqueIdNextCard(scenarios, nextCard.uniqueId)
          if (uniqueId) {
            // eslint-disable-next-line dot-notation
            nextCard['old_uniqueId'] = cloneDeep(nextCard.uniqueId)
            nextCard.uniqueId = uniqueId
          }
        }
      }
    }
    return scenarios
  }

  getUniqueIdNextCard(scenarios: ICard[], uniqueId) {
    for (const scenario of scenarios) {
      // eslint-disable-next-line dot-notation
      if (scenario['old_uniqueId'] === uniqueId) {
        return scenario.uniqueId
      }
    }
    return null
  }

  async mounted() {
    this.currentScenario = cloneDeep(this.value)
    window.addEventListener('message', (event) => this.handleMessage(event))

    await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITH_DETAIL, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITH_DETAIL, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS, {
      app_id: this.appId,
    })
    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.appId,
    })
  }

  beforeUnmount() {
    if (!this.isApply) {
      this.$emit('update:resetScenario', this.currentScenario)
    }
    window.removeEventListener('message', (event) => this.handleMessage(event))
  }
}
