import { render } from "./UnReachedUsersSetting.vue?vue&type=template&id=6d822362&scoped=true"
import script from "./UnReachedUsersSetting.vue?vue&type=script&lang=ts"
export * from "./UnReachedUsersSetting.vue?vue&type=script&lang=ts"

import "./UnReachedUsersSetting.vue?vue&type=style&index=0&id=6d822362&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6d822362"

export default script
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QToolbar,QToolbarTitle,QItem,QItemSection,QIcon,QItemLabel,QTooltip,QBtn});
